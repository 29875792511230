// routes for the app are here
// when making an app consider what the default root should be
// renders pages in the views folder

import React, { Suspense, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useMixPanel } from 'react-mixpanel-provider-component';
import _ from 'lodash';

import PropTypes from 'prop-types';
// material
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Cohere from 'cohere-js';

//
import LoadingScreen from '../components/LoadingScreen';

import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';

// ---- Import Pages ------------------------------------------------------------------

// const GeneralEcommerce = React.lazy(() => import('../views/GeneralEcommerce')); // Lazy-loaded

const ResetPassword = React.lazy(() =>
  import('../views/authentication/ResetPassword')
);

const Allocation = React.lazy(() => import('../views/Allocation'));
// ------------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  setUser: PropTypes.func,
  setjwtToken: PropTypes.func,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func,
  validationOpen: PropTypes.bool,
  jwtToken: PropTypes.string,
  searchData: PropTypes.array
};

function DashboardLayout({
  children,
  user,
  setUser,
  setjwtToken,
  jwtToken,
  currentFilters,
  setCurrentFilters,
  validationOpen,
  searchData
}) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        user={user}
        setUser={setUser}
        setjwtToken={setjwtToken}
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
        jwtToken={jwtToken}
        searchData={searchData}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        user={user}
        jwtToken={jwtToken}
        setCurrentFilters={setCurrentFilters}
        currentFilters={currentFilters}
        validationOpen={validationOpen}
      />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}

Routes.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  setjwtToken: PropTypes.func,
  jwtToken: PropTypes.string
};

export default function Routes({ user, setUser, setjwtToken, jwtToken }) {
  // const [valueMetric, setValueMetric] = useState("Retail");
  const [currentFilters, setCurrentFilters] = useState({});
  const [validationOpen, setValidationOpen] = useState(false);

  const [searchData, setSearchData] = useState([]);

  const { mixpanel } = useMixPanel();

  // declare usePrevious hook
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    Cohere.identify(
      user.email, // Required: can be any unique ID
      {
        displayName: `${user.name} ${user['custom:family_name']}`, // Optional
        email: user.email // Optional
      }
    );

    mixpanel.identify(user.email);
    mixpanel.people.set(
      {
        $name: `${user.name} ${user['custom:family_name']}`,
        $email: user.email,
        $avatar: user['custom:imageURL']
      },
      []
    );

    mixpanel.track('Signed in');
  }, []);

  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <div>
          <DashboardLayout
            user={user}
            setUser={setUser}
            setjwtToken={setjwtToken}
            jwtToken={jwtToken}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            validationOpen={validationOpen}
            searchData={searchData}
          >
            <Switch>
              <Route path="/changepassword">
                <ResetPassword />
              </Route>

              <Route exact path="/">
                <Allocation
                  jwtToken={jwtToken}
                  currentFilters={currentFilters}
                  user={user}
                  setCurrentFilters={setCurrentFilters}
                  setSearchData={setSearchData}
                />
              </Route>
            </Switch>
          </DashboardLayout>
        </div>
      </Suspense>
    </Router>
  );
}
