import axios from 'axios';

// const API = 'http://localhost:3000';

let API = '';

if (
  window.location.href.includes('allocation.lanvin.autone.io') &&
  !window.location.href.includes('dev')
) {
  // production API in use
  API = 'https://allocation.api.lanvin.autone.io';
} else {
  // development API in use
  API = 'https://dev-allocation.api.lanvin.autone.io';
  // production code
}

axios.defaults.baseURL = API;

// Allocation

export function getAllocation(jwtToken) {
  return axios.get('/allocation', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getAllocationSKUData(jwtToken) {
  return axios.get('/allocation_sku_data', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getAllocationProductData(jwtToken) {
  return axios.get('/allocation_product_data', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function postAllocationSKULocationData(jwtToken, skuCode) {
  return axios.post(
    '/allocation_location_sku_data',
    { skuCode },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function getNotifications(jwtToken) {
  return axios.get('/notifications', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getWarehouse(jwtToken) {
  return axios.get('/warehouse', {
    headers: {
      Authorization: jwtToken
    }
  });
}

// get filters

export function getFilters(jwtToken) {
  return axios.get('/filters', {
    headers: {
      Authorization: jwtToken
    }
  });
}

// get submission

export function getSubmission(jwtToken) {
  return axios.get('/submission', {
    headers: {
      Authorization: jwtToken
    }
  });
}

// remove products

export function removeCombination(jwtToken, data) {
  return axios.post(
    '/removecombination',
    { data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function removeGlobalCombination(jwtToken, productCode) {
  return axios.post(
    '/globalremovecombination',
    { product_code: productCode },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function unremoveGlobalCombination(jwtToken, productCode) {
  return axios.post(
    '/globalunremove',
    { product_code: productCode },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function afterRemoveSKUUpdate(jwtToken, skuCode) {
  return axios.post(
    '/after_remove_sku_update',
    { skuCode },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function saveComments(jwtToken, data) {
  return axios.post(
    '/comments',
    { data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function unRemoveLocationCombination(jwtToken, skuCode, locationCode) {
  return axios.post(
    '/unremovelocationcombination',
    {
      sku_code: skuCode,
      location_code: locationCode
    },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function edits(jwtToken, data) {
  return axios.post(
    '/edit',
    { data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function postEditUpdate(jwtToken, productCode, skuCode) {
  return axios.post(
    '/editsUpdates',
    { productCode, skuCode },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function allocationEmail(jwtToken, useremail) {
  return axios.post(
    '/email/allocationemail',
    { useremail },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function postFocusSKU(jwtToken, data) {
  return axios.post(
    '/focussku',
    { data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function postFocusProduct(jwtToken, data) {
  return axios.post(
    '/focusproduct',
    { data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function getFocus(jwtToken) {
  return axios.get('/getfocus', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getLocations(jwtToken) {
  return axios.get('/locations', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function postAdditionalLocation(jwtToken, skuCode, locationCode) {
  return axios.post(
    '/additionallocation',
    { skuCode, locationCode },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

// get filters

export function getConfirmedPush(jwtToken) {
  return axios.get('/confirmedpush', {
    headers: {
      Authorization: jwtToken
    }
  });
}

// delete item

export function deleteproduct(jwtToken, data) {
  return axios.post(
    '/deleteproduct',
    { ...data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function sendProductToOutlet(jwtToken, data) {
  return axios.post(
    '/sendtooutlet',
    { ...data },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export default {
  getAllocation,
  getWarehouse,
  getFilters,
  removeCombination,
  unremoveGlobalCombination,
  unRemoveLocationCombination,
  edits,
  getNotifications,
  allocationEmail,
  getSubmission,
  postFocusProduct,
  postFocusSKU,
  getFocus,
  getLocations,
  postAdditionalLocation,
  getAllocationSKUData,
  getAllocationProductData,
  removeGlobalCombination,
  postAllocationSKULocationData,
  postEditUpdate,
  afterRemoveSKUUpdate,
  getConfirmedPush,
  saveComments,
  deleteproduct,
  sendProductToOutlet
};
