import { Icon } from '@iconify/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import {
  experimentalStyled as styled,
  alpha,
  makeStyles
} from '@material-ui/core/styles';
import {
  Box,
  Input,
  Slide,
  InputAdornment,
  ClickAwayListener,
  Typography,
  List,
  ListItem
} from '@material-ui/core';
// components
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const SearchbarresultsStyle = styled('div')(({ theme }) => ({
  color: 'black',
  cursor: 'pointer',
  top: APPBAR_MOBILE,
  [theme.breakpoints.up('md')]: {
    top: APPBAR_DESKTOP,
    padding: theme.spacing(0, 0)
  },
  width: '100%',
  position: 'absolute',
  backgroundColor: 'white',
  zIndex: 999999,
  boxShadow: theme.customShadows.z8,
  maxHeight: '200px',
  overflowY: 'scroll',
  padding: '0px',
  borderRadius: '5px',
  left: '0px'
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  block: {
    display: 'block'
  },
  listItemText: {
    fontSize: '0.7em'
  },
  nested: {
    paddingLeft: theme.spacing(0, 1),
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    borderRadius: '3px',
    '&:hover': {
      background: '#f5f5f5'
    }
  },
  label: {
    ...theme.typography.body2
  }
}));
// ----------------------------------------------------------------------

export default function Searchbar({ setCurrentFilters, searchData }) {
  const [isOpen, setOpen] = useState(false);
  const [inputEntered, setInputEntered] = useState('');
  const [searchValues, setSearchValues] = useState([]);
  const [searchDataFlattened, setSearchDataFlattened] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const searchValuesFiltered = _.filter(searchDataFlattened, (o) =>
      o.value === null || inputEntered.length === 0
        ? false
        : o.value.toString().toLowerCase().includes(inputEntered.toLowerCase())
    );

    const searchValuesFlattenedDimensioned = _.uniq(
      searchValuesFiltered.map((a) => a.dimension)
    );

    const searchValuesTemp = [];

    searchValuesFlattenedDimensioned.map((dimension) =>
      searchValuesTemp.push({
        [dimension]: _.filter(
          searchValuesFiltered,
          (o) => o.dimension === dimension
        ).map((a) => a.value)
      })
    );
    setSearchValues(searchValuesTemp);
  }, [inputEntered]);

  useEffect(() => {
    const searchDataFlattened = [];

    searchData.forEach((o) => {
      Object.keys(o).forEach((key) => {
        searchDataFlattened.push({
          value: o[key],
          dimension: key,
          unique_value: o[key] + key
        });
      });
    });

    setSearchDataFlattened(_.uniqBy(searchDataFlattened, 'unique_value'));
  }, [searchData]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (selectedObject) => {
    const dimension = Object.keys(selectedObject)[0];
    const values = selectedObject[dimension];

    setCurrentFilters((prevState) => ({
      ...prevState,
      [dimension]: values
    }));
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={inputEntered}
              onChange={(event) => setInputEntered(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            {searchValues.length > 0 ? (
              <SearchbarresultsStyle>
                <List className={classes.root}>
                  {searchValues.map((searchItem) => (
                    <ListItemStyle
                      button
                      disableGutters
                      key={Object.keys(searchItem)[0]}
                      className={classes.block}
                      onClick={() => handleSelect(searchItem)}
                    >
                      <Typography
                        component="div"
                        variant="caption"
                        display="block"
                        noWrap
                      >
                        {Object.keys(searchItem)[0]}
                      </Typography>
                      <Typography
                        component="div"
                        variant="caption"
                        display="block"
                        noWrap
                      >
                        {searchItem[Object.keys(searchItem)[0]].join(',')}
                      </Typography>
                    </ListItemStyle>
                  ))}
                </List>{' '}
              </SearchbarresultsStyle>
            ) : null}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
