import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {
  alpha,
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { useMixPanel } from 'react-mixpanel-provider-component';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const SubIconStyle = styled('span')(({ theme }) => ({
  width: 22,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: 4,
    height: 4,
    content: "''",
    display: 'block',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform')
  }
}));

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  maxHeight: {
    maxHeight: '300px',
    overflowY: 'scroll'
  }
}));

SidebarDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setCurrentFilters: PropTypes.func,
  currentFilters: PropTypes.object,
  values: PropTypes.array
};

export default function SidebarDropDown({
  title,
  values,
  setCurrentFilters,
  currentFilters,
  open = false
}) {
  const [show, setShow] = useState(open);
  const { mixpanel } = useMixPanel();

  const classes = useStyles();

  const currentScopedFilters =
    currentFilters[title] === undefined ? [] : currentFilters[title];

  const handleShow = () => {
    setShow((show) => !show);
  };

  const handleFilterClick = (option) => {
    // check if value already selected

    if (!currentScopedFilters.includes(option)) {
      mixpanel.track('Filter applied', {
        filter: option,
        dimension: title
      });

      if (title in currentFilters) {
        // add item to array if the type (function,line ...) has already been added
        setCurrentFilters((prevState) => ({
          ...prevState,
          [title]: [...prevState[title], option]
        }));
      } else {
        // create object attribute as type hasn't been selected
        setCurrentFilters((prevState) => ({
          ...prevState,
          [title]: [option]
        }));
      }
    } else {
      mixpanel.track('Filter Removed', {
        filter: option,
        dimension: title
      });

      const currentScopedFiltersUpdate = currentScopedFilters.filter(
        (e) => e !== option
      );

      // if there are no more values selected under this type

      if (currentScopedFiltersUpdate.length === 0) {
        setCurrentFilters((prevData) => {
          const newData = { ...prevData };
          delete newData[title];
          return newData;
        });
      } else {
        setCurrentFilters((prevState) => ({
          ...prevState,
          [title]: currentScopedFiltersUpdate
        }));
      }
    }
  };

  return (
    <>
      <ListItemStyle
        button
        disableGutters
        onClick={handleShow}
        className={open ? 'isActiveRoot' : ''}
      >
        {/* <ListItemIcon>{getIcon(icon)}</ListItemIcon> */}
        <ListItemText disableTypography primary={title} />
        <Box
          component={Icon}
          icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListItemStyle>

      <Collapse in={show}>
        <List className={classes.maxHeight}>
          {values.map((option) => (
            <ListItemStyle
              button
              disableGutters
              key={option.option}
              onClick={() => handleFilterClick(option.option)}
              className={
                currentScopedFilters.includes(option.option)
                  ? 'isActiveSub'
                  : ''
              }
            >
              <ListItemIcon>
                <SubIconStyle className="subIcon" />
                <ListItemText disableTypography primary={option.option} />
              </ListItemIcon>
            </ListItemStyle>
          ))}
        </List>
      </Collapse>
    </>
  );
}
