// material
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMixPanel } from 'react-mixpanel-provider-component';

// utils

Selections.propTypes = {
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func
};

export default function Selections({ currentFilters, setCurrentFilters }) {
  const { mixpanel } = useMixPanel();

  const removedSelection = (filter) => {
    setCurrentFilters((prevData) => {
      const newData = { ...prevData };
      delete newData[filter];
      return newData;
    });

    mixpanel.track('Filter Removed', {
      filter: currentFilters[filter].join(', '),
      dimension: filter
    });
  };

  return (
    <>
      <Chip key="volume" label="Volume" />

      {Object.keys(currentFilters).map((filter) => (
        <Chip
          key={filter}
          label={
            currentFilters[filter].length > 5
              ? `${currentFilters[filter].length} values`
              : currentFilters[filter].join(', ')
          }
          onClick={() => {
            removedSelection(filter);
          }}
          onDelete={() => {
            removedSelection(filter);
          }}
        />
      ))}
    </>
  );
}
